<template>
    <div class="Accesslog">
        <div class="Accesslog_head">
             <el-input v-model="input" placeholder="请输入地址"></el-input>
             <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        </div>
        <div class="Accesslog_body">
        <el-table 
            :data="tableData"
            stripe
            style="width: 100%">
            <el-table-column
            width="180px"
            align="center"
            prop="CityId"
            label="城市id">
            </el-table-column>
            <el-table-column
            width="380px"
            align="center"
            prop="Region"
            label="地址">
            </el-table-column>
            <el-table-column
            align="center"
            prop="Country"
            label="国家">
            </el-table-column>
           <el-table-column
            align="center"
            prop="Continent"
            label="地区名称">
            </el-table-column>
          <el-table-column
            align="center"
            prop="Ip"
            label="访问ip地址">
            </el-table-column>
             <el-table-column
            align="center"
            prop="CreateDate"
            label="访问时间">
            </el-table-column>
           <el-table-column
            width="380px"
            align="center"
            prop="Remarks"
            label="访问说明">
            </el-table-column>
        </el-table>
        </div>
      <div style="width: 98%;margin:0 auto;background-color: #F0F7FD;">
        <pagination
            :total="total"
            :page.sync="listQuery.pageNum"
            :limit.sync="listQuery.pageSize"
            @pagination="getList"
          >
          </pagination>
      </div>
    </div>
</template>
<script>
import api from "../../api/Grantsandmilestones"
import pagination from "../../components/Pagination/index.vue"
  export default {
    data() {
      return {
        input:"",
        tableData: [],
        total:0,
        listQuery: {
            pageNum: 1,
            pageSize: 11,
        },
      }
    },
   components: {
    pagination,
  },
    created(){
        this.SysIplist()
    },
    methods:{
      search(){
           let params={
               key:this.input,
               PageIndex: this.listQuery.pageNum*1-1*1,
               PageSize: this.listQuery.pageSize,
           }
           api.SysIpData(params).then(res=>{
               console.log(res);
               if(res.data.Status==1){
                     this.tableData=res.data.Entity
               }else{
                   alert(res.data.Message)
               }
           })
      },    
      getList(val){//分页组件事件
      this.listQuery.pageNum=val.page
      this.SysIplist()
      },
      SysIplist(){
           let params={
               key:"",
               PageIndex: this.listQuery.pageNum*1-1*1,
               PageSize: this.listQuery.pageSize,
           }
           api.SysIpData(params).then(res=>{
               console.log(res);
               if(res.data.Status==1){
                     this.tableData=res.data.Entity
                     this.total=res.data.TotalRowsCount
               }else{
                   alert(res.data.Message)
               }
           })
       }
    }
  }
</script>
<style lang="scss" scoped>
.Accesslog{
    width: 100%;
    height: 100%;
    background-color: #F0F7FD;
    overflow: hidden;
        .Accesslog_head{
              margin: 20px;
            display: flex;
            .el-input{
                width: 300px;
            }
            .el-button{
                margin-left: 10px;
            }
        }
        .Accesslog_body{
            width: 1800px;
            height: 600px;
            margin-left: 20px;
            
        }
}
</style>